import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getProfile } from "../api/getProfile";
import { getClan } from "../api/getClan";
import { register } from "../api/register";
import { updateProfile } from "../api/updateProfile";
import { isEmpty } from "../utils/isEmpty";
import { formatUserCount } from "../utils/formatUserCount";
import { formatNumber } from "../utils/formatNumber";
import { useMediaQuery } from "react-responsive";
import { CircularProgress, Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Layout from "../components/Layout/Layout";
import EnergyModal from "../components/EnergyModal/EnergyModal";
import NavBar from "../components/NavBar/NavBar";

import { GetLevel } from "./service";

import "./HomePage.css";
import "./button.css";

// Constants
const initialCoinSize = 1;
const shrinkRate = 0.005;
const growIncrement = 0.017;
const coinScaleLevel1 = 1.19;
const coinScaleLevel2 = 1.44;
const coinScaleLevel3 = 1.71;
const maxCoinScale = 2;
const maxTime = 30;
const debounceDelay = 800;

const GradientCircularProgress = styled(CircularProgress)(({ theme }) => ({
  "&.MuiCircularProgress-root": {
    color: "#00000000", // Make the default color transparent
    position: "relative",
    zIndex: 1,
  },
  "& .MuiCircularProgress-circle": {
    strokeLinecap: "round", // Rounded edges for the starting point
    transition: "stroke-dashoffset 0.35s", // Smooth transition for progress change
  },
}));

const HomePage = () => {
  const navigate = useNavigate();

  const [points, setPoints] = useState(0);
  const [level, setLevel] = useState(0);
  const [coinScale, setCoinScale] = useState(initialCoinSize);
  const [isVibrating, setIsVibrating] = useState(false);
  const [energyCount, setEnergyCount] = useState(-1);
  const [energyPercentage, setEnergyPercentage] = useState(100);
  const [showEnergyModal, setShowEnergyModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [flyingText, setFlyingText] = useState([]);
  const [flyingTextContent, setFlyingTextContent] = useState("");
  const [initialPointsIncrement, setInitialPointsIncrement] = useState(null);
  const [pointsIncrement, setPointsIncrement] = useState(1);
  const [notification, setNotification] = useState("");
  const [notificationVisibile, setNotificationVisibile] = useState(false);
  const [clan, setClan] = useState(null);
  const [clanInfo, setClanInfo] = useState({});
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [dirFlag, setDirFlag] = useState(-1);
  const dropdownRef = useRef(null);
  const timerIntervalRef = useRef(null);
  const debounceTimeoutRef = useRef(null);
  const [progress, setProgress] = useState(0);

  const handleClick = () => {
    setProgress((prevProgress) =>
      prevProgress >= 100 ? 0 : prevProgress + 0.5
    );
  };

  const [remainingTimeForNextCharge, setRemainingTimeForNextCharge] = useState(
    30 * 60
  );
  const [maxEnergyCount, setMaxEnergyCount] = useState(0);
  const [isCharging, setIsCharging] = useState(false);
  let interval = "";

  const stopCharge = (energy) => {
    const token = sessionStorage.getItem("token");
    updateProfile(token, points, energy, false);
    setIsCharging(false);
    clearInterval(interval);
    interval = "";
  };

  const [start, setStart] = useState(false);

  useEffect(() => {
    // console.log("This is homepage of moon coin");
    const manageHomePage = async () => {
      const notificationData = localStorage.getItem("notification");
      if (notificationData) {
        setNotification(notificationData);
        setNotificationVisibile(true);
        localStorage.removeItem("notification");

        // console.log('Notification set:', notificationData);

        setTimeout(() => {
          setNotificationVisibile(false);
          setTimeout(() => setNotification(""), 3000);
        }, 2000);
      }
      const token = sessionStorage.getItem("token");
      if (token) {
        // console.log(token, "PPPPPPPPPPPPPPPPPPPPPPPPPP");
        const data = await getProfile(token);
        setPoints(data.user.score);
        setLevel(data.user.level);
        setEnergyCount(data.user.energyCount);
        setInitialPointsIncrement(data.user.multiTapLevel);
        setPointsIncrement(data.user.multiTapLevel);
        setFlyingTextContent(`+${data.user.multiTapLevel}`);
        setMaxEnergyCount(data.user.maxEnergyCount);
        if (data.user.isCharging) {
          setIsCharging(data.user.isCharging);
          setRemainingTimeForNextCharge(data.remainTime);
          // alert(data.remainTime);
          // startCharge();
        }
        if (data.user.clan) {
          // setClan('669fae1c36b3b2880ae91976');
          setClan(data.user.clan);
          const clanData = await getClan(data.user.clan, token);
          setClanInfo(clanData);
          if (clanData?.tapBonus > 0) {
            setInitialPointsIncrement(data.user.multiTapLevel + 1);
            setPointsIncrement(data.user.multiTapLevel + 1);
            setFlyingTextContent(`+${data.user.multiTapLevel + 1}`);
          }
          sessionStorage.setItem("clanId", data.user.clan);
        } else {
          setClan(null);
          setClanInfo({});
          sessionStorage.removeItem("clanId");
        }
        setIsLoading(false);
        // console.log(data.user.friends);
        sessionStorage.setItem("points", data.user.score);
        sessionStorage.setItem("level", data.user.level);
        // console.log("164_line level is  ====>> ", data.user.level);
        sessionStorage.setItem(
          "normalReferralCount",
          data.user.normalReferralCount
        );
        sessionStorage.setItem(
          "premiumReferralCount",
          data.user.premiumReferralCount
        );
        sessionStorage.setItem("multiTapLevel", data.user.multiTapLevel);
        sessionStorage.setItem(
          "dailyAttemptsLevel",
          data.user.dailyAttemptsLevel
        );
        sessionStorage.setItem("exchangeId", data.user.exchange);
      } else {
        const userId =
          new URLSearchParams(window.location.search).get("userId") || "";
        const userName =
          new URLSearchParams(window.location.search).get("userName") || "";
        sessionStorage.setItem("userName", userName);
        const firstName =
          new URLSearchParams(window.location.search).get("firstName") || "";
        sessionStorage.setItem("firstName", firstName);

        if (userId) {
          // console.log(userId, "++++++++++++++++++++++++");
          const registerResponse = await register(userId, userName, firstName);
          setPoints(registerResponse.user.score);
          setLevel(registerResponse.user.level);
          setMaxEnergyCount(registerResponse.user.maxEnergyCount);
          setEnergyCount(registerResponse.user.energyCount);
          setInitialPointsIncrement(registerResponse.user.multiTapLevel);
          setPointsIncrement(registerResponse.user.multiTapLevel);
          setFlyingTextContent(`+${registerResponse.user.multiTapLevel}`);
          setIsCharging(registerResponse.user.isCharging);
          if (registerResponse.user.isCharging) {
            setRemainingTimeForNextCharge(registerResponse.remainTime);
            // alert(registerResponse.remainTime);
            // startCharge();
          }
          if (!isEmpty(registerResponse.user.clan)) {
            setClan(registerResponse.user.clan);
            const clanData = await getClan(
              registerResponse.user.clan,
              registerResponse.token
            );
            setClanInfo(clanData);
            if (clanData?.tapBonus > 0) {
              setInitialPointsIncrement(
                registerResponse.user.multiTapLevel + 1
              );
              setPointsIncrement(registerResponse.user.multiTapLevel + 1);
              setFlyingTextContent(
                `+${registerResponse.user.multiTapLevel + 1}`
              );
            }
            sessionStorage.setItem("clanId", registerResponse.user.clan);
          } else {
            setClan(null);
            setClanInfo({});
            sessionStorage.removeItem("clanId");
          }
          setIsLoading(false);
          sessionStorage.setItem("token", registerResponse.token);
          sessionStorage.setItem("points", registerResponse.user.score);
          sessionStorage.setItem("level", registerResponse.user.level);
          // console.log("210_line level is  ====>> ", registerResponse.user.level);
          sessionStorage.setItem("chatId", userId);
          sessionStorage.setItem(
            "normalReferralCount",
            registerResponse.user.normalReferralCount
          );
          sessionStorage.setItem(
            "premiumReferralCount",
            registerResponse.user.premiumReferralCount
          );
          sessionStorage.setItem(
            "multiTapLevel",
            registerResponse.user.multiTapLevel
          );
          sessionStorage.setItem(
            "dailyAttemptsLevel",
            registerResponse.user.dailyAttemptsLevel
          );
          sessionStorage.setItem("exchangeId", registerResponse.user.exchange);
        } else {
          if (
            window.Telegram &&
            window.Telegram.WebApp &&
            window.Telegram.WebApp.initData
          ) {
            const params = new URLSearchParams(window.Telegram.WebApp.initData);
            const user = JSON.parse(decodeURIComponent(params.get("user")));
            // console.log(user, "--------------------");
            const registerResponse = await register(user.id, user.username);
            setPoints(registerResponse.user.score);
            setMaxEnergyCount(registerResponse.user.maxEnergyCount);
            setLevel(registerResponse.user.level);
            setEnergyCount(registerResponse.user.energyCount);
            setInitialPointsIncrement(registerResponse.user.multiTapLevel);
            setPointsIncrement(registerResponse.user.multiTapLevel);
            setFlyingTextContent(`+${registerResponse.user.multiTapLevel}`);
            setIsCharging(registerResponse.user.isCharging);
            if (registerResponse.user.isCharging) {
              setRemainingTimeForNextCharge(registerResponse.remainTime);
              // alert(registerResponse.remainTime);
              // startCharge();
            }
            if (!isEmpty(registerResponse.user.clan)) {
              setClan(registerResponse.user.clan);
              const clanData = await getClan(
                registerResponse.user.clan,
                registerResponse.token
              );
              setClanInfo(clanData);
              if (clanData?.tapBonus > 0) {
                setInitialPointsIncrement(
                  registerResponse.user.multiTapLevel + 1
                );
                setPointsIncrement(registerResponse.user.multiTapLevel + 1);
                setFlyingTextContent(
                  `+${registerResponse.user.multiTapLevel + 1}`
                );
              }
              sessionStorage.setItem("clanId", registerResponse.user.clan);
            } else {
              setClan(null);
              setClanInfo({});
              sessionStorage.removeItem("clanId");
            }
            setIsLoading(false);
            sessionStorage.setItem("token", registerResponse.token);
            sessionStorage.setItem("points", registerResponse.user.score);
            sessionStorage.setItem("level", registerResponse.user.level);
            // console.log("255_line level is  ====>> ", registerResponse.user.level);
            sessionStorage.setItem("chatId", user.id);
            sessionStorage.setItem(
              "normalReferralCount",
              registerResponse.user.normalReferralCount
            );
            sessionStorage.setItem(
              "premiumReferralCount",
              registerResponse.user.premiumReferralCount
            );
            sessionStorage.setItem(
              "multiTapLevel",
              registerResponse.user.multiTapLevel
            );
            sessionStorage.setItem(
              "dailyAttemptsLevel",
              registerResponse.user.dailyAttemptsLevel
            );
            sessionStorage.setItem(
              "exchangeId",
              registerResponse.user.exchange
            );
          } else {
            alert("Telegram WebApp API is not available.");
            console.error("Telegram WebApp API is not available.");
          }
        }
      }
      setStart(true);
    };
    manageHomePage();
    // let inc = sessionStorage.getItem('multiTapLevel');
    // let total = sessionStorage.getItem('points');
    // if(points == NaN){
    //     setPointsIncrement(inc);
    //     setFlyingText(`+${inc}`);
    //     setPoints(total);
    // }
  }, []);
  useEffect(() => {
    // console.log("here is the clan info ", clanInfo);
    sessionStorage.setItem("clanInfo", JSON.stringify(clanInfo));
  }, [clanInfo]);

  const dur = 120;

  const [nowEnergy, setNowEnergy] = useState(10);
  const [allEng, setAllEng] = useState(-1);
  const [charging, steCharging] = useState(false);

  const startCharging = () => {
    // console.log("startCharging fuction!");
    steCharging(true);
    if (allEng === 0) {
      let now = new Date();
      localStorage.setItem("dat", now.toString());
    }
    localStorage.setItem("isCharging", "1");
    let inter = setInterval(() => {
      setAllEng((prev) => {
        // console.log(prev);
        if (prev + 1 >= dur) setNowEnergy(30);
        if ((prev + 1) % dur == 0) {
          // console.log(energyCount);
          setEnergyCount((p) => p + 1);
        }
        if (prev + 1 >= maxEnergyCount * dur) {
          clearInterval(inter);
          steCharging(false);
          localStorage.setItem("exist_energy", String(30 * maxEnergyCount));
          localStorage.setItem("isCharging", "0");
          return;
        }
        return prev + 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (energyCount < 0) setEnergyCount(0);
  }, [energyCount]);

  var inter;

  const startSpendng = () => {
    // console.log("startSpending fuction!");
    if (nowEnergy === 30) {
      let now = new Date();
      localStorage.setItem("dat", now.toString());
    }
    localStorage.setItem("is_spending", "1");
    // console.log("spending start by", nowEnergy);
    inter = setInterval(() => {
      // if(!tapable){
      //     alert("isNoTapable");
      //     return;
      // }
      setNowEnergy((prev) => {
        // console.log("spending =>", prev);
        if (prev <= 1) {
          clearInterval(inter);
          // setTapable(false);
          // setTimeout(() => {
          //     setTapable(true);
          // }, 5000);
          setCoinScale(initialCoinSize);
          setPointsIncrement(initialPointsIncrement);
          setFlyingTextContent(`+${initialPointsIncrement}`);
          setCoinScale(initialCoinSize);

          setIsSpendable(true);
          if (isClickedOnce === false) {
            setEnergyCount((eng) => {
              // console.log("337 energyCount ===> ",eng);
              return eng - 1;
            });
          }
          localStorage.setItem("is_spending", "0");
          // setEnergyCount(energyCount - 1);
          let cn = Number(localStorage.getItem("exist_energy"));
          cn = cn - 30;
          localStorage.setItem("exist_energy", String(cn));
          let now = new Date();
          localStorage.setItem("dat", now.toString());
          if (cn === 0) {
            setEnergyCount(0);
            localStorage.setItem("isCharging", "1");
            setAllEng(0);
            startCharging();
            return 0;
          } else {
            return 30;
          }
        } else {
          return prev - 1;
        }
      });
    }, 1000);
  };

  const stopSpending = () => {
    clearInterval(inter);
  };
  useEffect(() => {
    if (start === false) return;
    // localStorage.removeItem("exist_energy");
    if (localStorage.getItem("exist_energy") === null) {
      // console.log("manageHomePage/token/init");
      localStorage.setItem("exist_energy", String(energyCount * 30));
      localStorage.setItem("isCharging", "0");
      // console.log("===>", energyCount);
      if (energyCount) {
        setNowEnergy(30);
      } else {
        setNowEnergy(0);
        startCharging();
      }
    } else {
      let isch = localStorage.getItem("isCharging");
      setNowEnergy();
      // console.log("isch=====>", isch);
      // console.log("manageHomePage/token/noInit");
      if (isch === "0") {
        let issp = localStorage.getItem("is_spending");
        let en = localStorage.getItem("exist_energy");
        en = Number(en);
        if (en < 0 || maxEnergyCount * 30 < en) {
          en = maxEnergyCount * 30;
          localStorage.setItem("exist_energy", String(en));
        }
        // console.log("en =====>", en);
        if (issp === "1") {
          let dat = new Date(localStorage.getItem("dat"));
          let now = new Date();
          let dif = Math.floor((now.getTime() - dat.getTime()) / 1000);
          // console.log(dat.toString(),"=>",now.toString() ,"=>dif =====>", dif);
          if (dif >= 30) {
            localStorage.setItem("exist_energy", String(en - 30));
            localStorage.setItem("is_spending", "0");
            setIsSpendable(true);
            if (en - 30 <= 0) {
              setNowEnergy(0);
              setEnergyCount(0);
              setAllEng(dif - 30);
              // localStorage.setItem("exist_energy", "0");
              // console.log("now1 ===> ", now);
              now.setSeconds(now.getSeconds() - dif + 30);
              // console.log("now2 ====>", now);
              localStorage.setItem("dat", now);
              startCharging();
            } else {
              // localStorage.setItem("exist_energy", String(en-30));
              setEnergyCount((en - 30) / 30);
              setNowEnergy(30);
            }
          } else {
            setNowEnergy(30 - dif);
            setEnergyCount(en / 30);
            localStorage.setItem("isCharging", "0");
            steCharging(false);
            setIsSpendable(false);
            startSpendng();
          }
        } else {
          if (en !== 0) {
            // console.log("This is the 409 line of homepage.jsx");
            setNowEnergy(30);
            setIsSpendable(true);
            setEnergyCount(en / 30);
          } else {
            setNowEnergy(0);
            setEnergyCount(0);
            setAllEng(0);
            startCharging();
          }
        }
      } else {
        let dat = new Date(localStorage.getItem("dat"));
        let now = new Date();
        let dif = Math.floor((now.getTime() - dat.getTime()) / 1000);
        if (dif >= maxEnergyCount * dur) {
          setEnergyCount(maxEnergyCount);
          setNowEnergy(30);
          localStorage.setItem("exist_energy", String(maxEnergyCount * 30));
          localStorage.setItem("isCharging", "0");
        } else {
          setAllEng(dif);
          setEnergyCount(Math.floor(dif / dur));
          setNowEnergy(30);
          startCharging();
        }
        // alert("charging");
        // let dat = new Date(localStorage.getItem("dat"));
        // let now = new Date();
        // let dif = (now.getTime() - dat.getTime()) / 1000;
        // let cn = dif / 1800;
        // let en = localStorage.getItem("exist_energy");
        // en = Number(en);
        // setNowEnergy(en%30);
        // startCharging();
      }
    }
  }, [start]);

  useEffect(() => {
    if (remainingTime !== null) {
      setEnergyPercentage((remainingTime / maxTime) * 100);
    }
  }, [remainingTime]);

  useEffect(() => {
    const shrinkInterval = setInterval(() => {
      setCoinScale((prevScale) => {
        const newScale =
          prevScale > initialCoinSize
            ? prevScale - shrinkRate
            : initialCoinSize;
        setIsVibrating(newScale > initialCoinSize);
        return newScale;
      });
    }, 100);

    return () => clearInterval(shrinkInterval);
  }, []);
  useEffect(() => {
    if (energyCount === 0) {
      setShowEnergyModal(true);
    } else {
      setShowEnergyModal(false);
      // setIsModalOpen(false);
    }
  }, [energyCount]);

  // useEffect(() => {
  //   if (charging) setIsModalOpen(true);
  //   else setIsModalOpen(false);
  // }, [charging]);

  useEffect(() => {
    return () => {
      clearInterval(timerIntervalRef.current);
    };
  }, []);

  const handleClickJoinClan = () => {
    navigate("/joinclan");
  };
  const levels = () => {
    navigate("/levels");
  };
  const flip = () => {
    navigate("/flip");
  };
  const friends = () => {
    navigate("/invite");
  };
  const earn = () => {
    navigate("/earn");
  };
  const boosts = () => {
    navigate("/boosts");
  };

  const [isSpendable, setIsSpendable] = useState(true);

  const handleScreenClick = async (event) => {
    let isch = localStorage.getItem("isCharging");
    console.log("this is updating");

    if (charging) {
      setIsModalOpen(true);
    }
    if (
      isDebouncing ||
      charging ||
      nowEnergy === 0 ||
      isch === "1" ||
      event.target.closest("button") ||
      event.target.closest(".header") ||
      event.target.closest(".points") ||
      event.target.closest(".energy-container") ||
      event.target.closest(".clan")
    ) {
      return;
    }

    // console.log("handleScreenClick function!");

    const boundingRect = event.currentTarget.getBoundingClientRect();

    if (energyCount === 0) {
      setIsModalOpen(true);
      return;
    }

    // stopCharge(energyCount);
    setIsClickedOnce(true);

    let newScale = coinScale + growIncrement;

    setIsVibrating(newScale > initialCoinSize);
    // console.log(newScale);

    if (newScale >= maxCoinScale) {
      newScale = maxCoinScale;
    }

    setCoinScale(newScale);

    if (newScale > coinScaleLevel3) {
      setPointsIncrement(() => initialPointsIncrement * 4);
      setFlyingTextContent(`+${pointsIncrement}`);
    } else if (newScale > coinScaleLevel2) {
      setPointsIncrement(() => initialPointsIncrement * 3);
      setFlyingTextContent(`+${pointsIncrement}`);
    } else if (newScale > coinScaleLevel1) {
      setPointsIncrement(() => initialPointsIncrement * 2);
      setFlyingTextContent(`+${pointsIncrement}`);
    } else {
      setPointsIncrement(() => initialPointsIncrement);
      setFlyingTextContent(`+${pointsIncrement}`);
    }

    const randomAngle = 45 + Math.random() * 90;
    let radians = (50 * Math.PI) / 180;
    if (dirFlag === -1) radians = (125 * Math.PI) / 180;
    const xOffset = Math.cos(radians) * 100;
    const yOffset = -Math.sin(radians) * 50; // Reduced from 100 to 50

    setDirFlag(dirFlag * -1);

    // Add random small offsets to x and y for separation
    const randomXOffset = Math.random() * 50 - 10; // Random value between -10 and 10
    const randomYOffset = Math.random() * 20 - 10; // Random value between -10 and 10

    const newText = {
      id: Date.now(),
      x: 150 + 5 * coinScale * dirFlag + randomXOffset,
      y: 280 - 10 * coinScale + randomYOffset,
      xOffset: xOffset,
      yOffset: yOffset,
      rotationAngle: `${90 - randomAngle}deg`,
      content: flyingTextContent,
    };

    setFlyingText((prevTexts) => [...prevTexts, newText]);

    setTimeout(() => {
      setFlyingText((prevTexts) =>
        prevTexts.filter((text) => text.id !== newText.id)
      );
    }, 1000);

    setPoints((prevScore) => {
      const token = sessionStorage.getItem("token");
      updateProfile(token, prevScore + pointsIncrement, 0, false);
      return prevScore + pointsIncrement;
    });
    if (isSpendable) {
      // console.log("the point of startSpending function!");
      setIsSpendable(false);
      startSpendng();
    }

    if (!isTimerActive) {
      const startPoints = points;
      setIsTimerActive(true);
      setRemainingTime(maxTime);

      timerIntervalRef.current = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            setCoinScale(initialCoinSize);
            // setTapable(false);
            // setTimeout(() => {
            //     setTapable(true);
            // }, 5000);

            setIsDebouncing(true);
            debounceTimeoutRef.current = setTimeout(() => {
              setIsDebouncing(false);
            }, debounceDelay);
            clearInterval(timerIntervalRef.current);
            setIsTimerActive(false);
            if (isClickedOnce) {
              setEnergyCount((prevEnergy) => {
                const newEnergyCount = prevEnergy - 1;
                const token = sessionStorage.getItem("token");
                if (newEnergyCount !== 0)
                  updateProfile(token, points, newEnergyCount, false);
                if (newEnergyCount === 0) {
                  updateProfile(token, points, newEnergyCount, true);
                  setRemainingTimeForNextCharge(60 * 30);
                  setIsCharging(true);
                  // startCharge();
                } else if (newEnergyCount !== 0 && interval !== "") {
                  stopCharge(newEnergyCount);
                }
                return newEnergyCount;
              });
            }
            setEnergyPercentage(100);
            setPointsIncrement(initialPointsIncrement);
            setFlyingTextContent(`+${initialPointsIncrement}`);
            const token = sessionStorage.getItem("token");
            updateProfile(token, points, energyCount, true);
            // if (coinScale < maxCoinScale) {   //2024/8/15
            // setPoints(startPoints);
            // } // 2024/8/15

            return maxTime;
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  console.log("this is state", isModalOpen);

  const handleClickClan = (id) => {
    navigate(`/joinClan/${id}`);
  };

  // const handleClickPreSale = () => {
  //     navigate('/presale');
  // }

  const isResponsive1 = useMediaQuery({ query: "(max-width: 360px)" });
  const isResponsive2 = useMediaQuery({ query: "(max-width: 375px)" });
  const isResponsive3 = useMediaQuery({ query: "(max-width: 414px)" });
  const isResponsive4 = useMediaQuery({ query: "(max-width: 480px)" });

  const [styles, setStyles] = useState({
    bottom: "60px",
    energyMarginTop: "0rem",
  });

  useEffect(() => {
    if (isResponsive4) {
      console.log("responsive4");
      setStyles({ ...styles, bottom: "120px", energyMarginTop: "1.25rem" });
    }
    if (isResponsive3) {
      console.log("responsive3");
      setStyles({ ...styles, bottom: "80px", energyMarginTop: "-2rem" });
    }
    if (isResponsive2) {
      console.log("responsive2");
    }
    if (isResponsive1) {
      console.log("responsive1");
    }
  }, []);

  const location = useLocation();

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search);
    if (queryParameters.get("from")) navigate("/earn");
  }, []);

  const handleSpeedUpClick = () => {
    navigate("/boosts");
  };

  const handleEarnCoinsClick = () => {
    navigate("/earn");
  };
  useEffect(() => {
    // Add class to body when this page is mounted
    document.body.classList.add("page-overflow-hidden");

    return () => {
      // Remove class when this page is unmounted
      document.body.classList.remove("page-overflow-hidden");
    };
  }, []);

  return (
    <Layout >
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="main-content">
          <div
            style={{
              width: "100%",
              paddingInline: "0.9375rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.6875rem",
            }}
          >
            {clan ? (
              <button
                disabled={isCharging}
                onClick={() => handleClickClan(clanInfo?.id)}
                className="clan"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
              >
                <img
                  src={clanInfo?.profilePictureUrl}
                  alt="Avatar"
                  style={{
                    width: "36px",
                    height: "36px",
                    position: "relative",
                    borderRadius: "100px",
                  }}
                ></img>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // gap: "8px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#F4F6F7",
                      textAlign: "start",
                      lineHeight: "16px",
                    }}
                  >
                    {clanInfo?.clanName}
                  </span>
                  <div style={{ display: "flex", gap: "10px" }}>
                    {clanInfo?.energyBonus > 0 && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1px",
                        }}
                      >
                        <img
                          src="/Images/Home/lock.png"
                          alt="Energy Icon"
                          style={{ width: "0.875rem", height: "0.875rem" }}
                        />
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "400",
                            fontSize: "0.5rem",
                            color: "white",
                            // marginLeft: "0.25rem",
                          }}
                        >
                          +{clanInfo?.energyBonus}
                        </span>
                      </div>
                    )}
                    {clanInfo?.tapBonus > 0 && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1px",
                        }}
                      >
                        <img
                          src="/Images/Home/icon.png"
                          alt="Multi Tap Icon"
                          style={{ width: "0.875rem", height: "0.875rem" }}
                        />
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "400",
                            fontSize: "0.5rem",
                            color: "white",
                            // marginLeft: "0.25rem",
                          }}
                        >
                          +{clanInfo?.tapBonus}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#1657FF",
                    // padding: "9px 7px",
                    marginLeft: "20px",
                    // opacity: "0.6",
                  }}
                >
                  {formatUserCount(clanInfo?.userCount)}
                  {clanInfo?.userCount > 1000 && "+"}
                </span>
              </button>
            ) : (
              <button className="topbutton" onClick={handleClickJoinClan}>
                Join Clan
                <img
                  src="/Images/Home/arrow.svg"
                  alt="/"
                  style={{ height: "28px", width: "28px" }}
                />
              </button>
            )}
            {/* <button style={{ width: '50%', borderRadius: '18.75rem', background: 'linear-gradient(45deg, #5DA8FF, #0064D7)', marginBlock: '0.3125rem', paddingBlock: '1rem', cursor: 'pointer', border: 'none', fontFamily: 'Inter', color: 'white', fontSize: '1.125rem', fontWeight: '600' }} onClick={handleClickPreSale} disabled={isCharging}>Presale</button> */}
          </div>

          <button className="level" onClick={levels}>
            <h2 className="level__heading">{formatNumber(points)}</h2>
            <p className="level__text">LEVEL {GetLevel(points)}</p>
          </button>
          {/* <div className="coin-container" onMouseDown={handleScreenClick}>
            <img
              src="/Images/Home/image.png"
              alt="Coin"
              className={`coin ${isVibrating ? "vibrating" : ""}`}
              style={{ "--coin-scale": coinScale, width: "100%" }}
            />
          </div> */}
          <div className="coin-container">
            <div className="progress-container">
              <svg
                className="progress-circle"
                viewBox="-5 -5 110 110"
                onClick={handleClick}
                onMouseDown={handleScreenClick}
              >
                <defs>
                  <linearGradient
                    id="gradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" style={{ stopColor: "#FE498A" }} />
                    <stop
                      offset="100%"
                      style={{ stopColor: "#5B2036", stopOpacity: 1 }}
                    />
                  </linearGradient>
                  <linearGradient id="dotGradient" cx="50%" cy="50%" r="50%">
                    <stop
                      offset="17%"
                      style={{ stopColor: "#101214", stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{ stopColor: "#FE498A", stopOpacity: 1 }}
                    />
                  </linearGradient>
                </defs>
                {/* Background Circle with Low Opacity */}
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  stroke="#FE498A"
                  strokeWidth="15"
                  fill="none"
                  opacity="0.1"
                />
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  stroke="url(#gradient)"
                  strokeWidth="15"
                  fill="none"
                  strokeDasharray="282.6"
                  strokeDashoffset={(1 - progress / 100) * 282.6}
                  strokeLinecap="round"
                  // style={{
                  //   transition: "stroke-dashoffset 0.5s ease-in-out",
                  // }}
                />
                {/* Moving Dot */}
                <circle
                  cx={50 + 45 * Math.cos((2 * Math.PI * progress) / 100)}
                  cy={50 + 45 * Math.sin((2 * Math.PI * progress) / 100)}
                  r="6.5"
                  fill="url(#dotGradient)"
                  // style={{
                  //   transition: "0.5s ease-out",
                  // }}
                />
                <image
                  x="30"
                  y="30"
                  width="40"
                  height="40"
                  href="/Images/Home/logo.png"
                />
              </svg>
              {/* <button onClick={handleClick}>Click to Increase</button> */}
            </div>
          </div>

          {notification && (
            <div
              style={{
                position: "absolute",
                bottom: "188px",
                zIndex: "2",
                width: "100%",
                paddingInline: "30px",
              }}
            >
              <div
                style={{
                  marginInline: "18.5px",
                  background: "#FEF1F61A",
                  backdropFilter: "blur(1rem)",
                  WebkitBackdropFilter: "blur(1rem)",
                  borderRadius: "20px",
                  paddingBlock: "16px",
                  paddingInline: "32px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: notificationVisibile ? 1 : 1,
                  transition: "opacity 0.5s ease-out",
                  pointerEvents: notificationVisibile ? "auto" : "none",
                  zIndex: "100",
                }}
              >
                <img
                  src="/Images/Home/notification.svg"
                  alt="Notification Icon"
                  style={{ width: "1.5625rem", height: "1.5625rem" }}
                />
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "18px",
                    color: "#F4F6F7",
                    marginLeft: "0.375rem",
                    textAlign: "center",
                    lineHeight: "24px",
                  }}
                >
                  {notification}
                </span>
              </div>
            </div>
          )}
          <div
            className="energy-container"
            // style={{ marginTop: styles?.energyMarginTop }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <img
                  src="/Images/Home/icon.png"
                  alt="Energy"
                  className="energy-image"
                />
                <span className="energy-number">
                  {energyCount}/{maxEnergyCount}
                </span>
              </div>
              <div>
                {charging ? (
                  ""
                ) : (
                  <div className="remaining-time">
                    {nowEnergy !== null
                      ? `${Math.floor(nowEnergy / 60)}m ${nowEnergy % 60}s`
                      : "0m 30s"}
                  </div>
                )}
              </div>
            </div>
            <div className="energy-bar-wrapper">
              <div className="original-energy-bar"></div>
              <div className="energy-bar-container">
                <div
                  className="energy-bar"
                  style={{
                    width: !charging
                      ? `${(100 * nowEnergy) / 30}%`
                      : `${
                          (100 * (maxEnergyCount - energyCount)) /
                          maxEnergyCount
                        }%`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: energyCount && !charging ? "#8675FF" : "red",
                  }}
                >
                  <div style={{ display: "flex", flexGrow: "1" }}></div>
                  {!!charging ? (
                    <span
                      style={{
                        color: "white",
                        fontFamily: "Montserrat",
                        fontSize: "8px",
                        fontStyle: "italic",
                        fontWeight: "800",
                        paddingRight: "6px",
                      }}
                    >
                      {Math.floor((dur * maxEnergyCount - allEng) / 60)}m{" "}
                      {(dur * maxEnergyCount - allEng) % 60}s
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "#101214",
                        fontFamily: "Montserrat",
                        fontSize: "8px",
                        fontStyle: "italic",
                        paddingRight: "6px",
                        fontWeight: "800",
                      }}
                    ></span>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isModalOpen && (
            <>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.1)", // Black background with 10% opacity
                  backdropFilter: "blur(5px)", // Backdrop blur effect
                  WebkitBackdropFilter: "blur(5px)", // Backdrop blur effect for Safari
                  zIndex: 998, // Behind the dropdown
                }}
              ></div>
              <div
                ref={dropdownRef}
                style={{
                  width: "90%",
                  borderRadius: "1.25rem 1.25rem 1.25rem 1.25rem",
                  background: "#8C8C8C1A",
                  backdropFilter: "blur(3rem)",
                  WebkitBackdropFilter: "blur(3rem)",
                  position: "fixed",
                  bottom: "40%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "32px 35px",
                  alignItems: "center",
                  // gap: "25px",
                  zIndex: "999",
                }}
                className="redeemModal"
              >
                <button
                  style={{
                    position: "absolute",
                    top: "1rem",
                    right: "1rem",
                    float: "right",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                >
                  <img
                    src={"/Images/Home/closeIcon.png"}
                    alt="Close Icon"
                    style={{ width: "1.875rem", height: "1.875rem" }}
                  />
                </button>
                <div className="modal-header">
                  <img
                    src="/Images/Home/attempts.png"
                    alt="Energy Modal Icon"
                    className="modal-icon"
                  />
                </div>
                <div className="modal-title">
                  <h2>Attempts Restoring</h2>
                </div>
                <div className="modal-body">
                  <p className="modal-message">
                    Meanwhile you can watch ads to <br /> restore attempts or
                    earn tokens by <br /> completing tasks
                  </p>
                </div>
                <div className="modal-footer">
                  <button onClick={handleSpeedUpClick} className="modal-button">
                    Speed Up
                  </button>
                  <button
                    onClick={handleEarnCoinsClick}
                    className="modal-button1"
                  >
                    {/* <img
              src="/Images/Home/coin.svg"
              alt="Coin Icon"
              className="button-icon"
            /> */}
                    Earn Coins
                  </button>
                </div>
              </div>
            </>
          )}
          {<NavBar />}
          {flyingText?.map((text) => (
            <div
              key={text.id}
              className="flying-text"
              style={{
                left: `${text.x}px`,
                top: `${text.y}px`,
                "--x-offset": `${text.xOffset}px`,
                "--y-offset": `${text.yOffset}px`,
                "--rotate-angle": text.rotationAngle,
                color: "#F4F6F7",
              }}
            >
              {text.content}
            </div>
          ))}
        </div>
      )}
    </Layout>
  );
};

export default HomePage;
