import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import {convertStringToNumber} from 'convert-string-to-number'

import Layout from "../components/Layout/Layout";
import NavBar from "../components/NavBar/NavBar";
import { getProfile } from "../api/getProfile";
import { getClan } from "../api/getClan";
import { register } from "../api/register";
import { updateProfile } from "../api/updateProfile";
import { isEmpty } from "../utils/isEmpty";
import { formatUserCount } from "../utils/formatUserCount";
import { useMediaQuery } from "react-responsive";
import { GetLevel } from "./service";
import { convertStringToNumber } from "convert-string-to-number";
import { updateBoostLevel } from "../api/updateBoostLevel";
import { getFlipStatus } from "../api/getFlipStatus";
import moveIcon from "../assets/moveIcon.png";
import tickIcon from "../assets/tickIcon.png";
import notificationIcon from "../assets/notificationIcon.png";
import notifyicon from "../assets/notifyicon.png";
// import multiTapIcon from "../assets/arm.png";
import energyIcon from "../assets/energy.png";
// import robot from "../assets/10.png";
import astronautIcon from "../assets/astronaut.png";
import dotIcon from "../assets/dot.svg";
import closeIcon from "../assets/closeIcon.svg";
import expand from "../assets/arrow-move.svg";
import loin from "../assets/boost-loin.svg";
import { formatNumber } from "../utils/formatNumber";
import back from "../assets/Back.svg";
import bird from "../assets/bird.svg";
import "./Boosts.css";

const initialCoinSize = 1;
const shrinkRate = 0.005;
const growIncrement = 0.017;
const coinScaleLevel1 = 1.19;
const coinScaleLevel2 = 1.44;
const coinScaleLevel3 = 1.71;
const maxCoinScale = 2;
const maxTime = 30;
const debounceDelay = 800;

const Earn = () => {
  const token = sessionStorage.getItem("token");
  const points = sessionStorage.getItem("points");
  // const level = sessionStorage.getItem('level');
  const level = Number(localStorage.getItem("level"));
  const [multiTapLevel, setMultiTapLevel] = useState(0);
  const [dailyAttemptsLevel, setDailyAttemptsLevel] = useState(0);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [notification, setNotification] = useState("");
  const [notificationVisibile, setNotificationVisibile] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [newDropdownVisible, setNewDropdownVisible] = useState(false);
  const [boostType, setBoostType] = useState("");
  const [score, setScore] = useState(0);
  const [mode, setMode] = useState(true);
  const [userScore, setUserScore] = useState(0);
  const requiredScoreForMultiTap = 2000 * 2 ** multiTapLevel;
  const requiredScoreForDailyAttempts = 2000 * 2 ** dailyAttemptsLevel;
  const formattedScoreForMultiTap = formatNumber(requiredScoreForMultiTap);
  const formattedScoreForDailyAttempts = formatNumber(
    requiredScoreForDailyAttempts
  );
  useEffect(() => {
    let ml = sessionStorage.getItem("multiTapLevel");
    ml = convertStringToNumber(ml);
    setMultiTapLevel(ml);
    let dl = sessionStorage.getItem("dailyAttemptsLevel");
    dl = convertStringToNumber(dl);
    setDailyAttemptsLevel(dl);
    const pnts = sessionStorage.getItem("points");
    setScore(pnts);
  }, []);
  const handleClickDouble = () => {
    navigate("/flip");
  };

  const handleClickMultiTap = () => {
    setBoostType("multiTap");
    setDropdownVisible(!dropdownVisible);
  };
const handleCoinClick = () =>{
  setNewDropdownVisible(true)
}

const handleButtonClickOfNew=()=>{
  setNewDropdownVisible(true)
}
  const handleClickDailyAttempts = () => {
    setBoostType("dailyAttempts");
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickMultiTapGet = async () => {
    const result = await updateBoostLevel(token, "multiTap");
    if (result == true) {
      setScore(score - requiredScoreForMultiTap);
      setMultiTapLevel(multiTapLevel + 1);
      setDropdownVisible(!dropdownVisible);
      setNotification("Multitap successfully added");
      setNotificationVisibile(true);
      setTimeout(() => {
        setNotificationVisibile(false);
        setTimeout(() => setNotification(""), 3000);
      }, 2000);
    }
  };

  const levels = () => {
    navigate("/levels");
  };

  useEffect(() => {
    const setFlipStatus = async () => {
      const flipStatus = await getFlipStatus(token);
      setMode(flipStatus);
    };
    setFlipStatus();
  }, []);

  const handleClickDailyAttemptsGet = async () => {
    const result = await updateBoostLevel(token, "dailyAttempts");
    if (result == true) {
      setScore(score - requiredScoreForDailyAttempts);
      setDailyAttemptsLevel(dailyAttemptsLevel + 1);
      setDropdownVisible(!dropdownVisible);
      setNotification("Attempts successfully added");
      setNotificationVisibile(true);
      setTimeout(() => {
        setNotificationVisibile(false);
        setTimeout(() => setNotification(""), 3000);
      }, 2000);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.closest(".boostButton")
      ) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownVisible, setDropdownVisible]);

  const closeDropdown = () => {
    setDropdownVisible(false);
  };
  const closeNewDropdown = () => {
    setNewDropdownVisible(false);
  };
  const handleClickJoinClan = () => {
    navigate("/joinclan");
  };

  const [clanInfo, setClanInfo] = useState({});
  const [clan, setClan] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("clanInfo") === "{}") {
      setClan(false);
      return;
    }
    setClan(true);
    setClanInfo(JSON.parse(sessionStorage.getItem("clanInfo")));
  }, []);

  const handleClickClan = (id) => {
    navigate(`/joinClan/${id}`);
  };


  return (
    <Layout>
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",

            marginTop: "30px",
          }}
          className="boost-top__d"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="boooost-top___d2"
          >
            <div
              style={{
                width: "100%",
                paddingInline: "0.9375rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.6875rem",
              }}
            >
              {clan ? (
                <button
                  // disabled={isCharging}
                  onClick={() => handleClickClan(clanInfo?.id)}
                  className="clan"
                >
                  <img
                    src={clanInfo?.profilePictureUrl}
                    alt="Avatar"
                    style={{
                      width: "40px",
                      height: "40px",
                      position: "relative",
                      borderRadius: "100px",
                    }}
                  ></img>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#F4F6F7",
                        textAlign: "start",
                        lineHeight: "16px",
                      }}
                    >
                      {clanInfo?.clanName}
                    </span>
                    <div style={{ display: "flex", gap: "10px" }}>
                      {clanInfo?.energyBonus > 0 && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1px",
                          }}
                        >
                          <img
                            src="/Images/Home/lock.png"
                            alt="Energy Icon"
                            style={{ width: "0.875rem", height: "0.875rem" }}
                          />
                          <span
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "400",
                              fontSize: "0.5rem",
                              color: "white",
                              // marginLeft: "0.25rem",
                            }}
                          >
                            +{clanInfo?.energyBonus}
                          </span>
                        </div>
                      )}
                      {clanInfo?.tapBonus > 0 && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1px",
                          }}
                        >
                          <img
                            src="/Images/Home/icon.png"
                            alt="Multi Tap Icon"
                            style={{ width: "0.875rem", height: "0.875rem" }}
                          />
                          <span
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "400",
                              fontSize: "0.5rem",
                              color: "white",
                              // marginLeft: "0.25rem",
                            }}
                          >
                            +{clanInfo?.tapBonus}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "700",
                      fontSize: "14px",
                      color: "#1657FF",
                      padding: "9px 7px",
                      marginLeft: "20px",
                      // opacity: "0.6",
                    }}
                  >
                    {formatUserCount(clanInfo?.userCount)}
                    {clanInfo?.userCount > 1000 && "+"}
                  </span>
                </button>
              ) : (
                <button className="topbutton" onClick={handleClickJoinClan}>
                  Join Clan
                  <img
                    src="/Images/Home/arrow.svg"
                    alt="/"
                    style={{ height: "34px", width: "34px" }}
                  />
                </button>
              )}
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <button className="level" onClick={levels}>
                <p className="numberstext">{Number(score)?.toLocaleString()}</p>
                <p className="leveltext">Level {GetLevel(score)}</p>
              </button>
            </div>
          </div>
          <div className="boosters">
            <div>
              <span
                style={{
                  color: "#F4F6F7",
                  fontFamily: "Inter",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "16px",
                }}
              >
                Boosters
              </span>
            </div>
            <div
              style={{
                width: "100%",

                border: "none",

                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                marginTop: "18px",
              }}
            >
              <button
                style={{
                  border: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: score < requiredScoreForMultiTap ? "auto" : "pointer",
                  width: "100%",
                  opacity: score < requiredScoreForMultiTap ? "1" : "1",
                }}
                onClick={
                  score < requiredScoreForMultiTap ? handleButtonClickOfNew : handleClickMultiTap
                }
                className="boostButton"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={"/Images/Boosts/Multitabicon.svg"}
                    alt="MultiTap Icon"
                    className="image-boost"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                    className="boost-multi__button"
                  >
                    <h1
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",

                        color: "#F4F6F7",

                        textAlign: "left",
                      }}
                      className="boost-multi__text"
                    >
                      Multi Tap
                    </h1>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "3.21px",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "500",

                          color: "#F4F6F7",

                          opacity: "50%",
                        }}
                        className="boost__score"
                      >
                        {formattedScoreForMultiTap}
                      </span>
                      {/* <img
                      src={dotIcon}
                      alt="Dot Icon"
                      style={{ width: "0.3125rem", height: "0.3125rem" }}
                    /> */}

                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "400",

                          color: "#F4F6F7",

                          opacity: "50%",
                        }}
                        className="boost__score"
                      >
                        {multiTapLevel} . lvl
                      </span>
                    </div>
                  </div>
                </div>
                {score < requiredScoreForMultiTap ? (
                  <img
                    src={"/Images/Boosts/expand.svg"}
                    alt="Tick Icon"
                    style={{ width: "1.25rem", height: "1.25rem" }}
                  />
                ) : (
                  <img
                    src={moveIcon}
                    alt="Move Icon"
                    style={{ width: "1.25rem", height: "1.25rem" }}
                  />
                )}
              </button>
              <button
                style={{
                  border: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor:
                    score < requiredScoreForDailyAttempts ? "auto" : "pointer",
                  width: "100%",
                  opacity: score < requiredScoreForDailyAttempts ? "1" : "1",
                }}
                onClick={
                  score < requiredScoreForDailyAttempts
                    ? handleButtonClickOfNew
                    : handleClickDailyAttempts
                }
                className="boostButton"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={"/Images/Boosts/attempt.png"} alt="Energy Icon" />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                    className="boost-multi__button"
                  >
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",

                        color: "#F4F6F7",

                        textAlign: "left",
                      }}
                      className="boost-multi__text"
                    >
                      Daily Attempts
                    </span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "3.21px",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "500",

                          color: "#F4F6F7",
                          lineHeight: "13.2px",
                          opacity: "50%",
                        }}
                        className="boost__score"
                      >
                        {formattedScoreForDailyAttempts}
                      </span>
                      {/* <img
                      src={dotIcon}
                      alt="Dot Icon"
                      style={{ width: "0.3125rem", height: "0.3125rem" }}
                    /> */}
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "400",

                          color: "#F4F6F7",
                          lineHeight: "13.2px",
                          opacity: "50%",
                        }}
                        className="boost__score"
                      >
                        {dailyAttemptsLevel} . lvl
                      </span>
                    </div>
                  </div>
                </div>
                {score < requiredScoreForDailyAttempts ? (
                  <img
                    src={"/Images/Boosts/expand.svg"}
                    alt="Tick Icon"
                    style={{ width: "1.25rem", height: "1.25rem" }}
                  />
                ) : (
                  <img
                    src={moveIcon}
                    alt="Move Icon"
                    style={{ width: "1.25rem", height: "1.25rem" }}
                  />
                )}
              </button>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginTop: "30px",
              alignItems: "center",
              background: "#FEF1F61A",
              // backdropFilter: "blur(1.5rem)",
              border: "none",
              // WebkitBackdropFilter: "blur(1.5rem)",
            }}
            className="booost-bottom-div"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={"/Images/Boosts/botmbuzz.png"} alt="/" />
              <h1 className="Boost-h1">Buzzmi x2</h1>
              <p className="Boost-p">
                Double your Buzzmi Points and Level Up even faster!
              </p>
            </div>
            <button className="boost-button" onClick={handleClickDouble}>
              Double
            </button>
          </div>
        </div>
        {notification && (
          <div
            style={{
              position: "absolute",
              bottom: "0%",
              zIndex: "2",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginInline: "1.875rem",
                background: "#FEF1F61A",
                backdropFilter: "blur(1rem)",
                WebkitBackdropFilter: "blur(0.5rem)",
                paddingInline: "15px",
                borderRadius: "300px",
                paddingBlock: "1.25rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                opacity: notificationVisibile ? 1 : 0,
                transition: "opacity 0.5s ease-out",
                poPoppinsEvents: notificationVisibile ? "auto" : "none",
              }}
            >
              <img src={"/Images/Boosts/notify.svg"} alt="Notification Icon" />
              <span
                style={{
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "18px",
                  color: "#F4F6F7",
                  marginLeft: "0.375rem",
                }}
              >
                {notification}
              </span>
            </div>
          </div>
        )}
        {dropdownVisible && (
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: "0px",

              backdropFilter: "blur(10px)",
              WebkitBackdropFilter: "blur(10px)",
              background: "#00000066",
              zIndex: "99",
            }}
            className="modalmain__top"
          >
            <div
              ref={dropdownRef}
              style={{
                width: "100%",
                borderRadius: "20px",
                background: "#2A2A2C",
                // WebkitBackdropFilter: "blur(114px)",
                // backdropFilter: " blur(67px)",
                border: "2px solid #ffffff15",
                flexDirection: "column",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                zIndex: "100",
              }}
              className="modal_inner"
            >
              <button
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  float: "right",
                  background: "none",
                  border: "none",
                  cursor: "poPoppins",
                }}
                onClick={closeDropdown}
              >
                <img
                  src={"/Images/Boosts/closeicon.svg"}
                  alt="Close Icon"
                  style={{ width: "1.875rem", height: "1.875rem" }}
                />
              </button>
              <img
                src={
                  boostType === "multiTap"
                    ? "/Images/Boosts/multimodal1.png"
                    : "/Images/Boosts/attemptmodal.png"
                }
                alt={
                  boostType === "multiTap" ? "Multi Tap Icon" : "Energy Icon"
                }
                style={{
                  width: boostType === "multiTap" ? "84px" : "auto",
                  height: boostType === "multiTap" ? "84px" : "84px",
                }}
              />
              <h1
                style={{
                  paddingTop: "25px",
                  fontFamily: "Inter",
                  fontWeight: "700",

                  color: "#F4F6F7",
                  textAlign: "center",
                }}
                className="modal___text1"
              >
                {boostType === "multiTap" ? "Multi Tap" : "Daily Attempts"}
              </h1>
              <span
                style={{
                  marginTop: "25px",
                  fontFamily: "Inter",
                  fontWeight: "400",

                  color: "#F4F6F7",
                  opacity: "50%",
                  textAlign: "center",
                }}
                className="boost__bottom_text2"
              >
                {boostType === "multiTap" ? (
                  <>
                    Increase the amount of Buzzmi pointsco you can <br /> earn per tap
                  </>
                ) : (
                  <>
                    Increase your Daily Attempts limit, so <br /> you can earn
                    more per session.
                  </>
                )}
              </span>
              <span
                style={{
                  fontFamily: "Inter",
                  fontWeight: "500",

                  color: "#F4F6F780",
                  marginTop: "25px",
                  textAlign: "center",
                  fontStyle: "italic",
                }}
                className="boost__bottom_text3"
              >
                {boostType == "multiTap"
                  ? "+1 per Tap for Each level"
                  : "+1 attempt for Each level"}
              </span>
              <span
                style={{
                  marginTop: "16px",
                  fontFamily: "Inter",
                  fontWeight: "700",
                  fontSize: "24px",
                  color: "#F4F6F7",
                  lineHeight: "24px",
                  letterSpacing: "-6%",
                }}
              >
                {boostType === "multiTap"
                  ? formattedScoreForMultiTap
                  : formattedScoreForDailyAttempts}
              </span>
              <button
                //  style={{ marginTop: '1.875rem', borderRadius: '18.75rem', background: 'linear-gradient(45deg, #5DA8FF, #0064D7)', width: '100%', paddingBlock: '1.125rem', color: 'white', fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.125rem', cursor: 'poPoppins', border: 'none' }}
                className="modelbutton"
                onClick={
                  boostType === "multiTap"
                    ? handleClickMultiTapGet
                    : handleClickDailyAttemptsGet
                }
              >
                Get
              </button>
            </div>
          </div>
        )}
        {newDropdownVisible && (
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: "0px",

              backdropFilter: "blur(10px)",
              WebkitBackdropFilter: "blur(10px)",
              background: "#00000066",
              zIndex: "99",
            }}
            className="modalmain__top"
          >
            <div
              ref={dropdownRef}
              style={{
                width: "100%",
                borderRadius: "20px",
                background: "#2A2A2C",
                // WebkitBackdropFilter: "blur(114px)",
                // backdropFilter: " blur(67px)",
                border: "2px solid #ffffff15",
                flexDirection: "column",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                zIndex: "100",
              }}
              className="modal_inner"
            >
              <button
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  float: "right",
                  background: "none",
                  border: "none",
                  cursor: "poPoppins",
                }}
                onClick={closeNewDropdown}
              >
                <img
                  src={"/Images/Boosts/closeicon.svg"}
                  alt="Close Icon"
                  style={{ width: "1.875rem", height: "1.875rem" }}
                />
              </button>
              <img
                src="/Images/Boosts/botmbuzz.png"
                alt={
                  boostType === "multiTap" ? "Multi Tap Icon" : "Energy Icon"
                }
                style={{
                  width: boostType === "multiTap" ? "84px" : "auto",
                  height: boostType === "multiTap" ? "84px" : "84px",
                }}
              />
              <span
                style={{
                  marginTop: "16px",
                  fontFamily: "Inter",
                  fontWeight: "700",
                  fontSize: "24px",
                  color: "#F4F6F7",
                  lineHeight: "24px",
                  letterSpacing: "-6%",
                }}
              >
                Not Enough Coins
              </span>
              <button
                //  style={{ marginTop: '1.875rem', borderRadius: '18.75rem', background: 'linear-gradient(45deg, #5DA8FF, #0064D7)', width: '100%', paddingBlock: '1.125rem', color: 'white', fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.125rem', cursor: 'poPoppins', border: 'none' }}
                className="modelbutton"
                onClick={()=>{navigate("/earn")}}
              >
                Get Points
              </button>
            </div>
          </div>
        )}
        <div style={{ height: "120px" }}></div>
      </>

      <NavBar />
    </Layout>
  );
};

export default Earn;
