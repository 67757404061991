import React, { useEffect } from "react";
import { useState, useRef } from "react";
import "./profile.css";
import Layout from "../../components/Layout/Layout";
import NavBar from "../../components/NavBar/NavBar";
import { API_URL, API_URL_ADMIN } from "../../utils/client";
import axios from "axios";
import { getProfile } from "../../api/getProfile";
import { updateProfilePicture } from "../../api/updateProfilePicture";
import { updateProfileUser } from "../../api/updateUserProfile";
import { sendTelegramMessage } from "../../api/sendTelegramMessage";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [telegramUsername, setTelegramUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [backgroundImage, setBackgroundImage] = useState(""); // Default image
  const fileInputRef = useRef(null); // Ref to trigger the hidden file input
  const [profileData, setProfileData] = useState();
  const [userIds, setUserIds] = useState();
  const [nameCheck, setNameCheck] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dropdownRef = useRef(null);
  const chatId = sessionStorage.getItem("chatId");

  // Handler for image selection
  // const handleImageSelect = (e) => {
  //   const file = e.target.files[0]; // Get the selected file
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       // Preview the selected image
  //       setBackgroundImage(event.target.result); // Set as background image
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  async function setUpUser() {
    try {
      const token = sessionStorage.getItem("token");
      setIsLoading(true);
      // Usage

      const data = await updateProfileUser(token, name, email, userIds);
      setIsModalOpen(true);
      setIsLoading(false);
      console.log(data);
    } catch (error) {
      console.log(error);
      // setIsloading(false);
    }
  }

  // const handleImageSelect = async (e) => {
  //   const file = e.target.files[0];
  //   // setProfileImageUpdate(true);

  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setBackgroundImage(reader.result);
  //       console.log(reader.result, "this is reader result");
  //     };
  //     reader.readAsDataURL(file);

  //     try {
  //       const token = sessionStorage.getItem("token");
  //       const formData = new FormData();
  //       formData.append("file", file);

  //       // const response = await axios
  //       //     .post(`${API_URL_ADMIN}/upload`, formData, {
  //       //       headers: {
  //       //         "Content-Type": "multipart/form-data",
  //       //       },
  //       //     })

  //       // console.log("This is response", response);

  //       // if (!response.ok) {
  //       //   throw new Error("Failed to upload profile picture");
  //       // }

  //       // const resData = await response.json();
  //       // setProfileImageUpdate(false);

  //       // console.log("Profile res", resData?.data);
  //       // setBackgroundImage(`https://storage.cloud.google.com/buzzmi-eb1cd//${response?.data?.fileName}?authuser=0`);
  //       const data = await updateProfilePicture(token, userIds, reader.result);
  //     } catch (error) {
  //       // setProfileImageUpdate(false);
  //       console.error("Error:", error);
  //     }
  //   }
  // };
  // https://storage.cloud.google.com/bluemoon_task_images//${mission.photoUrl}?authuser=0

  // Trigger the hidden file input on button click
  const handleButtonClick = () => {
    fileInputRef.current.click(); // Open file dialog
  };

  const handleImageSelect = async (e) => {
    setIsLoading(true);
    const message = "Please send me the command: /uploadPicture";
    await sendTelegramMessage(chatId, message);
    setIsLoading(false);
    if (
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.close
    ) {
      window.Telegram.WebApp.close();
    } else {
      console.error("Telegram WebApp API is not available.");
    }
  };
  useEffect(() => {
    setIsLoading(true);
    const token = sessionStorage.getItem("token");
    async function getData() {
      if (token) {
        const data = await getProfile(token);
        setIsLoading(false);
        setProfileData(data?.user);
        setName(data?.user?.name);
        setEmail(data?.user?.email);
        setUserIds(data?.user?._id);
        setTelegramUsername(data?.user?.userName);
        setBackgroundImage(data?.user?.profilePic);
      }
    }
    getData();
  }, []);

  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div className="profile__main-top">
            <div className="profile__main">
              {/* Div showing the current or uploaded profile picture */}
              {backgroundImage ? (
                <div
                  className="editProfile__main-img"
                  style={{
                    // backgroundImage: `url("/Images/profile/coins.png")`,
                    // backgroundSize: "cover",
                    // backgroundPosition: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={backgroundImage}
                    alt="/"
                    className="editProfile__main-img-upload"
                  />
                </div>
              ) : (
                <div className="editProfile__main-img">
                  <img src="/Images/profile/profile.svg" alt="/" />
                </div>
              )}

              {/* Hidden Input for image selection */}
              {/* <input
                type="file"
                accept="image/*"
                onChange={handleImageSelect} // Trigger when an image is selected
                ref={fileInputRef} // Reference to the hidden file input
                style={{ display: "none" }} // Hide the input
              /> */}

              {/* Button to open file input */}
              <button className="profile__main-btn" onClick={handleImageSelect}>
                Update Profile Picture
              </button>
            </div>
            <div className="profile__bottom">
              <div className="profile__bottom-input">
                <label>Telegram Username</label>
                <input
                  disabled
                  placeholder="@joebiden"
                  type="text"
                  value={telegramUsername}
                  onChange={(e) => setTelegramUsername(e.target.value)}
                />
              </div>

              <div className="profile__bottom-input">
                <label>Name</label>
                <input
                  placeholder="Enter your name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {nameCheck && (
                <p style={{ color: "red" }}>Name cannnot be empty</p>
              )}

              <div className="profile__bottom-input">
                <label>Email Address</label>
                <input
                  placeholder="Enter your email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {isModalOpen && (
                <>
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.1)", // Black background with 10% opacity
                      backdropFilter: "blur(5px)", // Backdrop blur effect
                      WebkitBackdropFilter: "blur(5px)", // Backdrop blur effect for Safari
                      zIndex: 998, // Behind the dropdown
                    }}
                  ></div>
                  <div
                    ref={dropdownRef}
                    style={{
                      width: "84%",
                      borderRadius: "1.25rem 1.25rem 1.25rem 1.25rem",
                      background: "#8C8C8C1A",
                      backdropFilter: "blur(3rem)",
                      WebkitBackdropFilter: "blur(3rem)",
                      position: "fixed",
                      bottom: "35%",
                      display: "flex",
                      flexDirection: "column",
                      padding: "32px 35px",
                      alignItems: "center",
                      // gap: "25px",
                      zIndex: "999",
                    }}
                    className="redeemModal"
                  >
                    <button
                      style={{
                        position: "absolute",
                        top: "1rem",
                        right: "1rem",
                        float: "right",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsModalOpen(false);
                      }}
                    >
                      <img
                        src={"/Images/Home/closeIcon.png"}
                        alt="Close Icon"
                        style={{ width: "1.875rem", height: "1.875rem" }}
                      />
                    </button>
                    <div className="modal-header">
                      <img
                        src="/Images/Home/attempts.png"
                        alt="Energy Modal Icon"
                        className="modal-icon"
                      />
                    </div>
                    <div className="modal-title">
                      <h2>Profile Updated</h2>
                    </div>
                    <div className="modal-body">
                      <p className="modal-message">
                        Your Profile has been updated successfully
                      </p>
                      <button
                        className="editprofile__btn-modal"
                        onClick={() => {
                          setIsModalOpen(false);
                        }}
                      >
                        Okay
                      </button>
                    </div>
                    {/* <div className="modal-footer">
                <button onClick={handleSpeedUpClick} className="modal-button">
                  Speed Up
                </button>
                <button onClick={handleEarnCoinsClick} className="modal-button1">
                
                  Earn Coins
                </button>
              </div> */}
                  </div>
                </>
              )}
            </div>

            <button
              onClick={() => {
                setUpUser();
              }}
              className="profile__bottom-btn"
            >
              Set Up
            </button>
          </div>
          <NavBar />
        </>
      )}
    </Layout>
  );
};

export default Profile;
